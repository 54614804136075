import { useRef } from 'react';
import { useRaceStore } from '../../../../store/raceSlice';
import { useRaceMessage } from '../../../../hooks/useRaceMessage';

import styles from './Race.module.scss';
import { useRoachStore } from '../../../../store/roachSlice';

const Race = () => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const raceData = useRaceStore((state) => state.raceRegisterData);
    const raceFrontUrl = process.env.REACT_APP_RACE_FRONT_URL || '';

    const currentRoach = useRoachStore((state) => state.currentRoach);

    console.log(raceData);

    const { isOver } = useRaceMessage();

    const handleRaceLoad = () => {
        if (iframeRef && iframeRef.current && iframeRef.current.contentWindow) {
            iframeRef.current.contentWindow.postMessage(
                { type: 'RACE_TOKEN', token: raceData?.token },
                raceFrontUrl,
            );
            console.log('onTokenSend');

            // setIsLoading(false);
        } else {
            console.log('Iframe not found but already loaded');
        }
    };

    return (
        <div className="w-full h-full">
            {raceData && currentRoach && (
                <iframe
                    ref={iframeRef}
                    className={styles.frame}
                    height={700}
                    onLoad={handleRaceLoad}
                    referrerPolicy="no-referrer"
                    title="race"
                    src={`${raceFrontUrl}/?game=${raceData?.gameUuid}&user=${currentRoach?.id}&raceBackUrl=${raceData?.raceBackUrl}`}
                />
            )}
            {isOver && (
                <div className="absolute top-0 w-full text-center text-white">LeaderBoard</div>
            )}
        </div>
    );
};

export default Race;
