import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { RoachInfoDto } from '../api/tor-api';

interface RoachState {
    currentRoach: RoachInfoDto | null;
    setCurrentRoach(newRoach: RoachInfoDto): void;
}

export const useRoachStore = create<RoachState>()(
    immer((set) => ({
        currentRoach: null,
        setCurrentRoach: (newData) => {
            set((state) => {
                state.currentRoach = newData;
            });
        },
    })),
);
