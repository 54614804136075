import React from 'react';
import clsx from 'clsx';
import { FC } from 'react';
import styles from './FarmingProgress.module.scss';
import { Countdown } from '../Countdown';
import { useStore } from '../../../../../store/globalStore';
import ClaimButton from '../ClaimButton/ClaimButton';
import { Wave } from '../Wave';

interface FarmingProgressProps {
    isLoadingClaim: boolean;
    onClaim(): Promise<void>;
    visible: boolean;
    onCountdownFinished(): void;
    isFarming: boolean;
}

const FarmingProgress: FC<FarmingProgressProps> = ({
    isLoadingClaim,
    visible,
    onClaim,
    isFarming,
    onCountdownFinished,
}) => {
    const config = useStore((state) => state.config);
    const farmingSession = useStore((state) => state.user?.farmSessionInfo);

    return (
        <div className={clsx(styles.wrapper, visible ? styles.active : null)}>
            <div className={styles.textContainer}>
                <h3>{isFarming ? 'Farming in progress' : 'Farming completed!'}</h3>
                {isFarming && (
                    <Countdown
                        endTime={farmingSession?.endTime}
                        serverTimeDiffMs={config?.serverTimeDiffMs}
                        className={styles.countdown}
                        onFinished={onCountdownFinished}
                    />
                )}
                <p>
                    {isFarming
                        ? 'Don’t forget to come back to claim the harvest!'
                        : 'Claim your MTGN and start farming again'}
                </p>
            </div>

            <div className={styles.buttonContainer}>
                <ClaimButton
                    isFarming={isFarming}
                    onClick={onClaim}
                    disabled={isFarming || isLoadingClaim}
                />
            </div>
            <Wave
                endTime={farmingSession?.endTime}
                serverTimeDiffMs={config?.serverTimeDiffMs}
                startTime={farmingSession?.startTime}
            />
        </div>
    );
};

export default React.memo(FarmingProgress, (prevProps, nextProps) => {
    return (
        prevProps.isFarming === nextProps.isFarming &&
        prevProps.visible === nextProps.visible &&
        prevProps.isLoadingClaim === nextProps.isLoadingClaim
    );
});
