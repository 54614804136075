import * as THREE from 'three';
import { AnimationClip, AnimationMixer, Clock } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Object3D } from 'three/src/core/Object3D';
import { sleep } from '../helpers/utils';
import { AnimationActionLoopStyles, LoopRepeat } from 'three/src/constants';

const LIGHT_COLOR = '#FFFFFF';

export class RoachConstructorView {
    private requestId: number;

    private root: HTMLElement;

    private readonly withSubstrate: boolean;

    private renderer!: THREE.WebGLRenderer;

    private scene!: THREE.Scene;

    private camera!: THREE.PerspectiveCamera;
    private controls: OrbitControls | null = null;
    animationMixer: AnimationMixer | null = null;
    slotAnimationMixer: AnimationMixer | null = null;
    clock: Clock = new Clock();

    constructor(root: HTMLElement, className?: string, withSubstrate = false) {
        console.log('constructor');
        this.root = root;
        this.withSubstrate = withSubstrate;

        this.initRenderer(className);
        this.initScene();
        this.initCamera();

        this.requestId = requestAnimationFrame(this.render);
    }

    public destroy = () => {
        cancelAnimationFrame(this.requestId);

        this.renderer.dispose();
        this.renderer.domElement.remove();
    };

    public setRoach(obj: THREE.Object3D) {
        this.scene.add(obj);
        this.startAnimation(obj);
    }

    public remove = (obj: THREE.Object3D) => {
        this.scene.remove(obj);
    };

    private initRenderer = (className?: string) => {
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            logarithmicDepthBuffer: true,
            alpha: true,
        });
        this.renderer.outputColorSpace = THREE.SRGBColorSpace;

        if (className) this.renderer.domElement.classList.add(className);
        this.root.appendChild(this.renderer.domElement);
    };

    private initScene = () => {
        this.scene = new THREE.Scene();

        const ambientLight = new THREE.AmbientLight(LIGHT_COLOR, 0.5);
        this.scene.add(ambientLight);

        const directionalLight = new THREE.DirectionalLight(LIGHT_COLOR, 1);
        directionalLight.position.set(7, 7, 7);
        directionalLight.target.position.set(0, 0, 0);
        this.scene.add(directionalLight);
        this.scene.add(directionalLight.target);

        if (this.withSubstrate) {
            const geometry = new THREE.CylinderGeometry(1, 1, 0.25, 20);
            const material = new THREE.MeshPhongMaterial({ color: '#45FF00' });
            const mesh = new THREE.Mesh(geometry, material);
            mesh.position.y = -1 * (mesh.geometry.parameters.height / 2);

            this.scene.add(mesh);
        }

        // buy new roach script
        new GLTFLoader().load('/roach/slot_empty.glb', async (newSlot) => {
            this.scene.add(newSlot.scene);
            this.startSlotAnimation(newSlot.scene, newSlot.animations[0], THREE.LoopOnce);
            const startTime = Date.now();

            // TODO: run roach animations

            console.log('model.animations[0].duration', newSlot.animations[0].duration);
            const chargeSlot = await new GLTFLoader().loadAsync('/roach/slot_charge.glb');
            const finishTime = Date.now();
            await sleep(newSlot.animations[0].duration * 1000 - (finishTime - startTime));

            this.scene.remove(newSlot.scene);
            this.scene.add(chargeSlot.scene);
            this.startSlotAnimation(chargeSlot.scene, chargeSlot.animations[0], THREE.LoopRepeat);
        });

        this.loadEnvMap('roach/GSG_PRO_STUDIOS_METAL_002_sm.hdr');
    };

    protected loadEnvMap(url: string) {
        new RGBELoader().load(url, (envMap) => {
            // eslint-disable-next-line no-param-reassign
            envMap.mapping = THREE.EquirectangularReflectionMapping;
            this.scene.environment = envMap;
            envMap.dispose();
        });
    }

    private initCamera = () => {
        const canvas = this.renderer.domElement;

        this.camera = new THREE.PerspectiveCamera(
            47,
            canvas.clientWidth / canvas.clientHeight,
            0.01,
            150,
        );

        this.controls = new OrbitControls(this.camera, canvas);
        this.controls.target.set(0, 1, 0);
        this.controls.autoRotate = false;
        this.controls.enableDamping = true;
        this.controls.addEventListener('start', () => {
            if (this.controls) {
                this.controls.autoRotate = false;
            }
        });

        this.camera.position.set(-1, 1, 3.3);
        this.camera.lookAt(0, 1, 0);
    };

    private render = () => {
        const deltaTime = this.clock.getDelta();
        if (this.controls) {
            this.controls.update();
        }

        if (this.animationMixer) {
            this.animationMixer.update(deltaTime);
        }
        if (this.slotAnimationMixer) {
            this.slotAnimationMixer.update(deltaTime);
        }

        if (this.resizeRendererToDisplaySize()) {
            const canvas = this.renderer.domElement;
            this.camera.aspect = canvas.clientWidth / canvas.clientHeight;
            this.camera.updateProjectionMatrix();
        }

        this.renderer.render(this.scene, this.camera);

        this.requestId = requestAnimationFrame(this.render);
    };

    private resizeRendererToDisplaySize = () => {
        const canvas = this.renderer.domElement;

        const box = this.root.getBoundingClientRect();
        if (!box) {
            return false;
        }

        const width = box.width;
        const height = box.height;

        const needResize = canvas.width !== width || canvas.height !== height;

        if (needResize) this.renderer.setSize(width, height, false);

        return needResize;
    };

    private async startAnimation(roach: Object3D) {
        const idleAnimation = await new GLTFLoader().loadAsync('/roach/idle01.glb');

        this.animationMixer = new AnimationMixer(roach);
        const clip = idleAnimation.animations[0];
        const action = this.animationMixer.clipAction(clip);
        const animationAction = action.play();
        console.log('animationAction', animationAction);
    }

    private startSlotAnimation(
        object: Object3D,
        animationClip: AnimationClip,
        mode: AnimationActionLoopStyles,
    ) {
        this.slotAnimationMixer = new AnimationMixer(object);
        const action = this.slotAnimationMixer.clipAction(animationClip);
        if (mode !== LoopRepeat) {
            action.setLoop(mode, 1);
        }
        const animationAction = action.play();
        console.log('slotAnimationAction', animationAction);
    }
}
