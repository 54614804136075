import { useEffect, useState } from 'react';

export const useRaceMessage = () => {
    const [isOver, setIsOver] = useState(false);

    useEffect(() => {
        const handleMessageReceiving = (e: MessageEvent) => {
            if (e.origin !== process.env.REACT_APP_FRONT_URL) return;

            if (e.data === 'gameDeleted') {
                setIsOver(true);
                console.log('game deleted');
            }
        };

        window.addEventListener('message', handleMessageReceiving);

        return () => window.removeEventListener('message', handleMessageReceiving);
    }, []);

    return { isOver };
};
