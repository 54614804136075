import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { torApi } from '../../../api/tor-api';
import { useRaceStore } from '../../../store/raceSlice';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../../store/globalStore';

export const useRoaches = () => {
    return useInfiniteQuery({
        queryKey: ['roaches'],
        queryFn: ({ pageParam }) => torApi.api.getApiV1Roaches({ page: pageParam }),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPage) => {
            const nextPage = lastPage.data.page !== allPage.length ? allPage.length : null;
            return nextPage;
        },
    });
};

export const useBuyRoach = () => {
    const queryClient = useQueryClient();
    const mergeUser = useStore((state) => state.mergeUser);

    return useMutation({
        mutationFn: torApi.api.postApiV1RoachesBuy,
        onSuccess: (data) => {
            const updatedUser = data.data.user;

            mergeUser(updatedUser);
            queryClient.invalidateQueries({ queryKey: ['roaches'] });
        },
    });
};

export const useRaceRegister = () => {
    const setRace = useRaceStore((state) => state.setRaceData);
    const navigate = useNavigate();
    return useMutation({
        mutationFn: torApi.api.postApiV1RaceRegister,
        onSuccess: (data) => {
            setRace(data.data);
            navigate('/race');
        },
    });
};
