import styles from './Button.module.scss';
const UpgradeButton = () => {
    return (
        <button className={styles.button}>
            <span className={styles.icon}>🚀</span>Upgrade
        </button>
    );
};

export default UpgradeButton;
