import { CSSProperties, FC, useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from './Wave.module.scss';
import { getServerTime } from '../../../../../helpers/utils';

type Props = {
    endTime?: string;
    startTime?: string;
    serverTimeDiffMs?: number;
};

export const Wave: FC<Props> = ({ endTime, startTime, serverTimeDiffMs }) => {
    const [currentDate, setCurrentDate] = useState<number | null>(() =>
        serverTimeDiffMs ? getServerTime(serverTimeDiffMs).getTime() - 1000 : null,
    );
    const endDate = endTime ? new Date(endTime).getTime() : null;
    const startDate = startTime ? new Date(startTime).getTime() : null;
    const totalDiff = endDate && startDate ? endDate - startDate : null;
    const progress =
        currentDate && endDate && currentDate > endDate
            ? 100
            : currentDate && startDate && currentDate < startDate
              ? 0
              : totalDiff && currentDate && startDate
                ? (Math.min(currentDate - startDate, totalDiff) * 100) / totalDiff
                : 0;

    useEffect(() => {
        const calc = () =>
            setCurrentDate(serverTimeDiffMs ? getServerTime(serverTimeDiffMs).getTime() : null);
        calc();
        const timerId = setInterval(calc, 1000);
        return () => clearInterval(timerId);
    }, [serverTimeDiffMs, startTime, endTime]);

    return (
        <div
            className={clsx(styles.root)}
            style={
                {
                    '--progress': `-${progress}%`,
                } as CSSProperties
            }
        >
            <div className={clsx(styles.inner)}>
                <svg
                    className={styles.svg}
                    width="1917"
                    height="735"
                    viewBox="0 0 1917 735"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.000356169 0.0022143C283.285 1.18738 389.715 52.1552 671.775 47.4506C901.01 43.6271 963.537 0.370311 1193.82 0.0022143C1428.25 -0.372488 1493.39 46.9322 1727.8 47.4506C1968.21 47.9823 2041.61 6.59696 2279 0.0022143C2279 79.5413 2279 734.5 2279 734.5H0.00021416C0.00021416 734.5 -0.000125691 79.5414 0.000356169 0.0022143Z"
                        fill="url(#paint0_radial_6540_137497)"
                    />
                    <defs>
                        <radialGradient
                            id="paint0_radial_6540_137497"
                            cx="0"
                            cy="0"
                            r="1"
                            gradientUnits="userSpaceOnUse"
                            gradientTransform="translate(1139 735) rotate(-89.9623) scale(759.524 13390.7)"
                        >
                            <stop offset="0.0475947" stopColor="#005B49" stopOpacity="0.5" />
                            <stop offset="1" stopColor="#3EDC03" stopOpacity="0.8" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>
        </div>
    );
};
