import { useEffect, useRef, useState } from 'react';
import { ChargingStats } from '../../../modules/ChargingStats';
import { HomeButton } from '../../../modules/HomeButton';
import { Status } from '../../../modules/StatusBar';
import { UpgradeButton } from '../../../modules/Upgrade';
import styles from './Home.module.scss';
import { useStore } from '../../../store/globalStore';
import { RoachConstructorView } from '../../../components/RoachConstuctor';
import { RoachBodyConfig } from '@kindexz/public';
import { LoadingManager } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { useRoaches } from '../api/roachQuery';
import { convertTraitsArray } from '../../../helpers/utils';
import { useRoachStore } from '../../../store/roachSlice';
import { Loader } from '../../../components/Loader/Loader';

const Home = () => {
    const setIsShowIntro = useStore((state) => state.setIsShowIntro);
    const viewRef = useRef<RoachConstructorView | null>(null);
    const [roach, setRoach] = useState<RoachBodyConfig | null>(null);
    const [isLoadingModel, setIsLoadingModel] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    const { data } = useRoaches();
    const { currentRoach, setCurrentRoach } = useRoachStore();

    const roaches = data?.pages[0].data.result;

    const roachTrait =
        data?.pages[0].data.result && data?.pages[0].data.result.length > 0
            ? data?.pages[0].data.result[0].traits
            : null;

    console.log('roach id', currentRoach?.id);

    useEffect(() => {
        if (roachTrait) {
            const newRoach = convertTraitsArray(roachTrait);
            setRoach(newRoach);
        }
    }, [roachTrait]);

    useEffect(() => {
        setIsShowIntro(false);
    }, [setIsShowIntro]);

    const handleLoadModel = async (roach: RoachBodyConfig) => {
        const loadingManager = new LoadingManager();
        const loader = new GLTFLoader(loadingManager);

        loadingManager.onStart = () => {
            setIsLoadingModel(true);
        };

        try {
            const loadedRoachData = await loader.loadAsync(
                `https://tor-race-dev.roachracing.club/roach/constructor/model/config/${JSON.stringify(roach)}`,
            );

            // if (prevGroup.current) viewRef.current?.remove(prevGroup.current);
            //
            // isNeedLoad.current = false;
            // prevGroup.current = loadedRoachData.scene;
            viewRef.current?.setRoach(loadedRoachData.scene);
        } catch (e) {
            // add notify
        }

        setIsLoadingModel(false);
    };

    useEffect(() => {
        if (containerRef.current && roach) {
            viewRef.current = new RoachConstructorView(
                containerRef.current,
                styles['model-wrapper'],
                false,
            );

            handleLoadModel(roach);

            return () => {
                viewRef.current?.destroy();
            };
        }

        return () => {
            viewRef.current?.destroy();
        };
    }, [roach]);

    useEffect(() => {
        if (data?.pages[0].data.result && data?.pages[0].data.result.length > 0) {
            const newRoach = data.pages[0].data.result[0];
            setCurrentRoach(newRoach);
        }
    }, [data, setCurrentRoach]);

    return (
        <main className={styles.container}>
            <Status />

            <div id="containerRef" className={styles.roachContainer} ref={containerRef}></div>

            <div className={styles.bottom}>
                {currentRoach && (
                    <div className={styles.rowContainer}>
                        <ChargingStats currentEnergy={currentRoach?.currentEnergy} />
                        <UpgradeButton />
                    </div>
                )}

                <div className={styles.buttonContainer}>
                    <HomeButton roaches={roaches} />
                </div>
            </div>
            {isLoadingModel && (
                <div className={styles.loader}>
                    <Loader />
                </div>
            )}
        </main>
    );
};

export default Home;
