import { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import styles from './Button.module.scss';
import { useStore } from '../../../store/globalStore';
import { useBuyRoach, useRaceRegister } from '../../../pages/Home/api/roachQuery';
import { useRoachStore } from '../../../store/roachSlice';
import { useRaceStore } from '../../../store/raceSlice';
import { RoachInfoDto, UserInfoDto } from '../../../api/tor-api';

enum HomeButtonState {
    Unlock = 'UNLOCK',
    Race = 'RACE',
    Charge = 'CHARGE',
    Charging = 'CHARGING',
    inRace = 'INRACE',
}

interface Props {
    roaches: RoachInfoDto[] | undefined;
}

const HomeButton: FC<Props> = ({ roaches }): ReactElement => {
    console.log('button render');
    const user = useStore((state) => state.user);
    const currentRoach = useRoachStore((state) => state.currentRoach);
    const inRace = useRaceStore((state) => state.inRace);

    const { mutate: buyRoach, isSuccess: isBuySuccess } = useBuyRoach();
    const { mutate: raceRegister, isPending: isRegisterPending } = useRaceRegister();
    const navigate = useNavigate();

    const cost = user?.nextRoachPriceInfo.cost || 0;
    const roachId = currentRoach?.id || 0;

    function getHomeButtonType(
        inRace: boolean,
        currentRoach: RoachInfoDto | null,
        user: UserInfoDto | null,
        roaches: RoachInfoDto[] | undefined,
    ): HomeButtonState {
        console.log('button type', inRace, currentRoach, user);
        console.log('roaches', roaches);
        if (inRace) {
            return HomeButtonState.inRace;
        } else if (currentRoach && currentRoach.currentEnergy <= 50) {
            return HomeButtonState.Charging;
        } else if ((roaches && roaches.length > 0) || isBuySuccess) {
            return HomeButtonState.Race;
        } else return HomeButtonState.Unlock;
    }

    const type = getHomeButtonType(inRace, currentRoach, user, roaches);

    const isAvailable =
        user?.balance &&
        user?.nextRoachPriceInfo.cost >= 0 &&
        user?.balance >= user?.nextRoachPriceInfo.cost;

    const handleUnlockClick = () => buyRoach({ name: user?.username || 'player' });
    const handleRaceClick = () => raceRegister({ roachId, templateId: 'trefoil' });

    switch (type) {
        case HomeButtonState.Unlock:
            return (
                <button
                    className={clsx(styles.button, !isAvailable && styles.disabled)}
                    onClick={handleUnlockClick}
                    disabled={!isAvailable}
                >
                    Unlock: {cost} MGTN
                </button>
            );
        case HomeButtonState.Charge:
            return <button className={styles.button}>Charge</button>;
        case HomeButtonState.Charging:
            return (
                <button className={clsx(styles.button, styles.progress)} disabled>
                    Charging 7h:59m
                </button>
            );
        case HomeButtonState.Race:
            return (
                <button
                    className={clsx(styles.button, isRegisterPending && styles.disabled)}
                    onClick={handleRaceClick}
                    disabled={isRegisterPending}
                >
                    Go to Race
                </button>
            );
        case HomeButtonState.inRace:
            return (
                <button
                    className={clsx(styles.button, styles.progress)}
                    onClick={() => navigate('/race')}
                >
                    Back to race
                </button>
            );
        default:
            return (
                <button className={styles.button} onClick={handleRaceClick}>
                    Go to Race
                </button>
            );
    }
};

export default HomeButton;
