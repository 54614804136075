import { EggModel, EggState } from './EggModel';
import { Vector3 } from 'three';
import { clamp, lerp, lerpVector3, randomRange } from '../../../helpers/utils';

export const BASE_SUBTRACT = 3;
export const RADIUS = 0.07;

export interface Ball {
    pos: Vector3;
    visualPos: Vector3;
    speed: Vector3;
    r: number;
    maxr: number;
    mass: number;
    drag: number;
    subtract: number;
}

export interface PhysicsModel {
    balls: Array<Ball>;
}

export class EggPhysics {
    private _data: PhysicsModel;
    private eggModel: EggModel;
    private time: number = 0;
    lastDragPoint: Vector3 | null = null;

    constructor(eggModel: EggModel) {
        this.eggModel = eggModel;
        this._data = {
            balls: [],
        };
        // this._data.balls.push({
        //     pos: new Vector3(0, 0, 0),
        //     speed: new Vector3(0, 0, 0),
        //     visualPos: new Vector3(),
        //     r: 0.1,
        //     mass: 1,
        // });
        // this._data.balls.push({
        //     pos: new Vector3(1, 0, 0.0),
        //     speed: new Vector3(-0.3, 0, 0),
        //     visualPos: new Vector3(),
        //     r: 0.1,
        //     mass: 1,
        // });
        // for (let i = 0; i < 7; i++) {
        //     this._data.balls.push(this.createBallData());
        // }
    }

    public createBallData(index: number) {
        const dx = index === 0 ? 0 : 0.1;
        let pos = new Vector3(randomRange(-dx, dx), randomRange(-dx, dx), randomRange(-dx, dx));
        return this.createBallDataWithPos(pos);
    }

    public createBallDataWithPos(pos: Vector3) {
        let r = RADIUS; // randomRange(0.1, 0.1 + index*0.01);
        const sx = 0.0;
        // radius = sqrt(strength / subtract)
        return {
            pos: pos,
            visualPos: pos,
            speed: new Vector3(
                randomRange(-sx, sx) /* + pos.z*/,
                randomRange(-sx, sx),
                randomRange(-sx, sx) /*- pos.x*/,
            ),
            r: r,
            maxr: r,
            mass: r * r,
            drag: 0,
            subtract: BASE_SUBTRACT,
        };
    }

    get data(): PhysicsModel {
        return this._data;
    }

    public animate(deltaTime: number) {
        if (deltaTime === 0) {
            return;
        }
        this.checkModel();
        const MAX_DELTA = 0.01;
        // limit deltaTime
        // deltaTime = Math.min(0.1, deltaTime);
        if (deltaTime <= MAX_DELTA) {
            this.animateRaw(deltaTime);
        } else {
            let iterationCount = Math.ceil(deltaTime / MAX_DELTA);
            let dividedDelta = deltaTime / iterationCount;
            // console.log('deltaTime', deltaTime, iterationCount, dividedDelta);
            for (let i = 0; i < iterationCount; i++) {
                this.animateRaw(dividedDelta);
            }
        }
    }

    animateRaw(deltaTime: number) {
        // function c(v: Vector3) {
        //     return `${v.x.toFixed(3)},${v.y.toFixed(0)},${v.z.toFixed(3)}`;
        // }

        this.time += deltaTime;
        // if (this.time > 0) {
        //     return;
        // }

        const center = new Vector3();

        if (this.eggModel.dragPoint /*&& this.eggModel.stateCopy === EggState.HATCHING*/) {
            this.lastDragPoint = this.eggModel.dragPoint;
            if (this.eggModel.draggedBall === null) {
                let minDist = Number.MAX_VALUE;
                for (let i = 0; i < this._data.balls.length; i++) {
                    let ball = this._data.balls[i];
                    let newDist = ball.pos.distanceToSquared(this.eggModel.dragPoint);
                    if (minDist > newDist) {
                        minDist = newDist;
                        this.eggModel.draggedBall = i;
                        // console.log('drag', i, ball.drag);
                    }
                    const touchDist = ball.r * 8;
                    ball.drag = newDist <= touchDist ? (touchDist - newDist) / touchDist : 0;
                }
                if (
                    this.eggModel.draggedBall != null &&
                    minDist < this.data.balls[this.eggModel.draggedBall].r * 2
                ) {
                    this.eggModel.draggedBall = null;
                }
            }
        } else {
            this.eggModel.draggedBall = null;
            for (let i = 0; i < this._data.balls.length; i++) {
                let ball = this._data.balls[i];
                ball.drag = 0; // clamp(ball.drag - 2 * deltaTime, 0, 1);
            }
        }

        // push away from bulb point
        // if (this.eggModel.stateCopy === EggState.GROWTH) {
        //     if (this.eggModel.pushNeeded) {
        //         this.eggModel.pushNeeded = false;
        //         if (this._data.balls.length >= 3) {
        //             for (let i = 0; i < this._data.balls.length; i++) {
        //                 const ball = this._data.balls[i];
        //                 const origin = this.eggModel.pushOrigin;
        //                 const distance = ball.pos.distanceTo(origin);
        //                 const force = -0.3 / Math.pow(distance * 10, 3) * this._data.balls.length;
        //                 const direction = origin.clone().sub(ball.pos);
        //                 direction.y = 0;
        //                 direction.normalize();
        //                 const impulse = direction
        //                     .multiplyScalar(force);
        //                 this.applyImpulse(ball, impulse);
        //             }
        //         }
        //     }
        // }

        if (this.lastDragPoint /*&& this.eggModel.stateCopy === EggState.HATCHING*/) {
            for (let i = 0; i < this._data.balls.length; i++) {
                const ball = this._data.balls[i];
                let distance = ball.pos.distanceTo(this.lastDragPoint);
                let r = ball.r * (2 - ball.drag);
                if (/*distance > r && */ ball.drag > 0) {
                    const impulse = this.lastDragPoint
                        .clone()
                        .sub(ball.pos)
                        // .divideScalar(deltaTime)
                        .multiplyScalar(distance - r);
                    // impulse.y = 0;
                    const prevPos = ball.pos.clone();
                    // if (this.eggModel.draggedBall === i) {
                    //     this.move(ball, impulse);
                    // } else {
                    this.move(ball, impulse.multiplyScalar(0.1 * Math.pow(ball.drag, 1.1)));
                    //}
                    // ball.speed = lerpVector3(ball.speed, impulse.clone().multiplyScalar(1 / deltaTime), 0.9);
                    let newSpeed = ball.pos
                        .clone()
                        .sub(prevPos)
                        .multiplyScalar(1 / deltaTime);
                    // newSpeed.y = 0;
                    //console.log('ball.speed', c(ball.speed));
                    // ball.pos.copy(this.eggModel.dragPoint);
                    this.applyImpulse(ball, newSpeed);
                }
            }
        }

        // move
        for (let i = 0; i < this._data.balls.length; i++) {
            const b = this._data.balls[i];
            b.pos.x += b.speed.x * deltaTime;
            b.pos.y += b.speed.y * deltaTime;
            b.pos.z += b.speed.z * deltaTime;
            // friction
            // if (this.eggModel.draggedBall !== i)
            {
                const force = this.eggModel.stateCopy === EggState.GROWTH ? 1 : 0.1;
                const power = Math.pow(b.speed.length(), 3) * force;
                b.speed.multiplyScalar(Math.max(0.01, 1 - deltaTime * power));
            }
        }

        for (let i = 0; i < this._data.balls.length; i++) {
            const a = this._data.balls[i];
            // control rotation speed
            // if (this.eggModel.draggetBall === null) {
            //     const px = a.pos.x - center.x;
            //     const pz = a.pos.z - center.z;
            //     let angle = Math.atan2(pz, px);
            //     const targetSpeedX = Math.sin(angle) * this.eggModel.rotationSpeed * 0.1;
            //     const targetSpeedZ = -Math.cos(angle) * this.eggModel.rotationSpeed * 0.1;
            //     const force2 = 0 * Math.abs(this.eggModel.rotationSpeed);
            //     const dx = targetSpeedX - a.speed.x - px * force2;
            //     const dz = targetSpeedZ - a.speed.z - pz * force2;
            //     const force = 0.5;
            //
            //     this.applyImpulse(a, new Vector3(dx * force, 0, dz * force));
            // }

            // gravity (move closer to center)
            if (this.eggModel.draggedBall !== i) {
                const d = a.pos.clone().sub(center);
                // if (this.eggModel.stateCopy !== EggState.HATCHING) {
                //     d.x = 0;
                //     d.z = 0;
                // }
                const distance = d.length();
                if (distance > 0) {
                    d.normalize();
                    let force = -Math.pow(distance, 2) * 0.02;
                    // if (Math.abs(this.eggModel.rotationSpeed) > 1) {
                    //     force += 10 * Math.abs(this.eggModel.rotationSpeed);
                    // }
                    this.applyImpulse(a, new Vector3(d.x * force, d.y * force, d.z * force));
                }
            }

            // metaballs sin/cos
            if (
                this.eggModel.draggedBall !== i &&
                // && this.data.balls.length > 1
                this.eggModel.stateCopy !== EggState.DISAPPEAR
            ) {
                const ballx =
                    Math.sin(
                        i + 1.26 * this.time * (1.03 + 0.5 * Math.cos(0.21 * (i + this.time))),
                    ) * 0.27;
                const bally =
                    Math.cos(i + 1.12 * this.time * 0.21 * Math.sin(0.72 + 0.83 * i)) * 0.27;
                const ballz =
                    Math.cos(i + 1.32 * this.time * 0.1 * Math.sin(0.92 + 0.53 * i)) * 0.27;

                const force = this.eggModel.temperature * deltaTime;
                this.applyImpulse(a, new Vector3(ballx * force, bally * force, ballz * force));
            }

            // collisions
            this._data.balls.forEach((b) => {
                if (a !== b) {
                    const d = a.pos.clone().sub(b.pos);
                    const distance = d.length();
                    const minDistance = (a.r + b.r) / 2;
                    if (distance < minDistance && distance > 0) {
                        const force = (minDistance - distance) / distance;
                        this.move(a, new Vector3(d.x * force, d.y * force, d.z * force));
                        this.move(b, new Vector3(-d.x * force, -d.y * force, -d.z * force));
                        const t = 0.8;
                        const speeda = a.speed.clone().multiplyScalar(t);
                        const speedb = b.speed.clone().multiplyScalar(t);

                        {
                            const dnorm = d.clone().normalize();
                            const impulse1 = dnorm.clone().multiply(speeda).length();
                            const impulseAtoB = dnorm.clone().multiplyScalar(impulse1);
                            this.applyImpulse(a, impulseAtoB);
                            this.applyImpulse(b, impulseAtoB.negate());
                        }

                        {
                            const dnormInv = d.clone().normalize().negate();
                            const impulse2 = dnormInv.clone().multiply(speedb).length();
                            const impulseBtoA = dnormInv.clone().multiplyScalar(impulse2);
                            this.applyImpulse(b, impulseBtoA);
                            this.applyImpulse(a, impulseBtoA.negate());
                        }
                    }
                }
            });

            {
                let minDist = Number.MAX_VALUE;
                let nearestBall: Ball | null = null;
                let nearestBallIndex: number | null = null;
                // min dist to nearest
                for (let j = i + 1; j < this._data.balls.length; j++) {
                    const b = this._data.balls[j];
                    if (a !== b) {
                        let distance = a.pos.distanceTo(b.pos);
                        if (distance < minDist) {
                            minDist = distance;
                            nearestBall = b;
                            nearestBallIndex = j;
                        }
                    }
                }
                if (nearestBall !== null && minDist > a.r + nearestBall.r) {
                    const midPoint = lerpVector3(
                        a.pos,
                        nearestBall.pos,
                        nearestBall.r / (a.r + nearestBall.r),
                    );
                    const force = 1;
                    if (i !== this.eggModel.draggedBall) {
                        this.moveAndImpulse(
                            a,
                            midPoint
                                .clone()
                                .sub(a.pos)
                                .normalize()
                                .multiplyScalar(a.pos.distanceTo(midPoint) - a.r),
                            force * deltaTime,
                        );
                    }
                    if (nearestBallIndex !== this.eggModel.draggedBall) {
                        this.moveAndImpulse(
                            nearestBall,
                            midPoint
                                .clone()
                                .sub(nearestBall.pos)
                                .normalize()
                                .multiplyScalar(
                                    nearestBall.pos.distanceTo(midPoint) - nearestBall.r,
                                ),
                            force * deltaTime,
                        );
                    }
                }
            }
            // spring each other
            this._data.balls.forEach((b) => {
                if (a !== b) {
                    const dx = a.pos.x - b.pos.x;
                    const dy = a.pos.y - b.pos.y;
                    const dz = a.pos.z - b.pos.z;

                    const targetDistance = a.r + b.r; /* + (this.eggModel.temperature)*/
                    const distance = a.pos.distanceTo(b.pos);
                    if (distance > targetDistance) {
                        const force = 25 * (targetDistance - distance) * deltaTime;
                        this.applyImpulse(a, new Vector3(dx * force, dy * force, dz * force));
                        this.applyImpulse(b, new Vector3(-dx * force, -dy * force, -dz * force));
                    }
                }
            });
        }

        // adjust balls draw (fit to center of screen)
        this._data.balls.forEach((b) => {
            // let strength = b.r * this.eggModel.ballRadiusScale;
            const radius = lerp(0.3, 2, b.r); // Math.sqrt(strength / b.subtract);
            if (Number.isNaN(radius)) {
                console.error('NAN radius');
            }
            let vr = radius / 2;
            let limit = 0.5;
            let pos2 = b.pos.clone();
            pos2.y = 0;
            let distanceToCenter = pos2.length() + vr;
            if (distanceToCenter > limit) {
                let overDistance = distanceToCenter - limit;
                this.moveAndImpulse(b, pos2.normalize().multiplyScalar(-overDistance), deltaTime);
            }

            b.pos.x = clamp(b.pos.x, -limit + vr, limit - vr);
            b.pos.y = clamp(b.pos.y, -limit + vr, limit - vr);
            b.pos.z = clamp(b.pos.z, -limit + vr, limit - vr);

            b.visualPos.x = b.pos.x;
            b.visualPos.y = b.pos.y;
            b.visualPos.z = b.pos.z;
        });
    }

    private checkModel() {
        while (this._data.balls.length < Math.min(1, this.eggModel.ballCount)) {
            this._data.balls.push(this.createBallData(this._data.balls.length));
            console.log('Push ball', this._data.balls.length);
        }
        while (this._data.balls.length > this.eggModel.ballCount) {
            this._data.balls.pop();
        }
    }

    private applyImpulse(a: Ball, impulse: Vector3) {
        a.speed.x += impulse.x;
        a.speed.y += impulse.y;
        a.speed.z += impulse.z;
        if (Number.isNaN(a.speed.x)) {
            console.error('NAN applyImpulse', a, impulse);
        }
    }

    private move(a: Ball, shift: Vector3) {
        a.pos.x += shift.x;
        a.pos.y += shift.y;
        a.pos.z += shift.z;
        if (Number.isNaN(a.pos.x)) {
            console.error('NAN move', a, shift);
        }
    }

    private moveAndImpulse(a: Ball, direction: Vector3, deltaTime: number) {
        this.move(a, direction);
        this.applyImpulse(a, direction.clone().multiplyScalar(1 / deltaTime));
    }
}
