import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { RaceRegisterResponseDto } from '../api/tor-api';

interface RaceState {
    raceRegisterData: RaceRegisterResponseDto | null;
    inRace: boolean;
    setRaceData(newData: RaceRegisterResponseDto | null): void;
}

export const useRaceStore = create<RaceState>()(
    immer((set) => ({
        raceRegisterData: null,
        inRace: false,
        setRaceData: (newData) => {
            set((state) => {
                if (newData) {
                    state.raceRegisterData = newData;
                    state.inRace = true;
                } else {
                    state.raceRegisterData = null;
                    state.inRace = false;
                }
            });
        },
    })),
);
