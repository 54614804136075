import { useState } from 'react';
import styles from './index.module.scss';
import { Loader } from '../../FirstScreen/Loader/Loader';
import clsx from 'clsx';

export const Race = () => {
    const [isLoadingVideo, setIsLoadingVideo] = useState(false);

    return (
        <div className={styles.wrapper}>
            <div className={styles.hero}>
                <h2>Manage. Race. Win.</h2>
                <figure>
                    <div className={styles.mediaWrapper}>
                        <video
                            className={styles.media}
                            poster="/media/trefoil.jpg"
                            autoPlay
                            muted
                            loop
                            playsInline
                            onCanPlayThrough={() => setIsLoadingVideo(false)}
                            onLoadStart={() => setIsLoadingVideo(true)}
                        >
                            <source src="/media/trefoil.mp4" type="video/mp4" />
                            <source src="/media/trefoil.webm" type="video/webm" />
                            <track kind="captions" label="trefoil" />
                            Your browser doesn&apos;t support HTML5 video tag.
                        </video>
                        <div
                            className={clsx(
                                styles.loaderContainer,
                                isLoadingVideo && styles.active,
                            )}
                        >
                            <Loader />
                        </div>
                    </div>

                    <figcaption className={styles.videoCaption}>
                        Join the Roach Racing Club to become <br /> an intergalactic Crypto Coach.
                        <br />
                        <br />
                        Farm Mutagen, incubate bio-mechanical Roaches, and train, breed, and race
                        them against other players in real-time. Compete with friends to earn even
                        more!
                    </figcaption>
                </figure>
            </div>
            <div className={styles.description}>
                <article>
                    <h3>Phase I: Mutagen Farming</h3>
                    <p>
                        Mutagen (MTGN) is a sacred liquid that unlocks essential game functions and
                        plays a critical role in future airdrop distributions.
                    </p>
                </article>
                <article>
                    <h3>Phase II: Genesis Roach Distribution</h3>
                    <p>
                        To play the game, you need a Roach! The Shadow Syndicate will initiate
                        Genesis Roach distribution to prepare Coaches for the upcoming races.
                    </p>
                </article>
                <article>
                    <h3>Phase III: Grand Opening</h3>
                    <p>
                        A historic moment – the very first races, tournaments, and competitions with
                        exclusive rewards. Rumors suggest the Trefoil Track will be revealed to the
                        public.
                    </p>
                </article>
            </div>
        </div>
    );
};
